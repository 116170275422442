import React from 'react';
// import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { H1 } from 'common/H1';

const IndexPage = () => (
  <Layout>
    <SEO title='Home' keywords={['developer', 'web', 'software', 'engineer']} />
    <H1>Hello there!</H1>
    <p>
      I'm a software developer specializing in web development. This website (or
      the beginnings of one) is under construction.
    </p>
  </Layout>
);

export default IndexPage;
